import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Form, Row, Col, Button } from 'react-bootstrap';
import ChatBotpreviewStore from './ChatbotpreviewStore';
import CustomScroll from 'react-custom-scroll';

const ChatPreviewComponent = observer(class ChatPreviewComponent extends Component {
    constructor(props) {
        super(props);
        this.chatBotpreviewStore = new ChatBotpreviewStore(this.props.appstore); // Access the store.
    }

    componentDidMount() {
        this.chatBotpreviewStore.fetchEmbeddedFilepaths(this.props.appstore); // Fetch embedded file paths.
    }

    render() {
        const { scriptStore, appstore } = this.props;
        const { messages } = this.chatBotpreviewStore; // Access the messages array.

        return (
            <div className="chatPreview" style={{ maxWidth: "550px",height:"400px" }}>
                <div className="headerTitle active">
                    <h6 className="text-uppercase">{appstore.getLanguageTrans("Test Your ChatBot")}</h6>
                </div>
                <div className="chatBox" style={{height:"374px"}}>
                    <div className="msgArea text-left">
					<CustomScroll heightRelativeToParent="100%" keepAtBottom={true} allowOuterScroll={true}>
					{
                        <div>
                            {messages.map((msg, index) => (
                                <div key={index} className={`message ${msg.sender}`}>
                                    <strong>{msg.sender === "user" ? "You: " : "AI: "}</strong>
                                    {msg.text}
                                </div>
                            ))}
                        </div>
					}
					</CustomScroll>
                    </div>
                    <div>
                        <Form.Group as={Row} controlId="formGroupMsg">
                            <Col sm="12" className="pl-0">
                                <Form.Control type="text" 
                                    placeholder={appstore.getLanguageTrans("Enter message")}
                                    value={scriptStore.chatUserData}
                                    onFocus={() => (scriptStore.previewOnFocus = true)}
                                    onBlur={() => (scriptStore.previewOnFocus = false)}
                                    onChange={(e) => (scriptStore.chatUserData = e.target.value)}
                                />
                            </Col>
                        </Form.Group>
                        <div className="row m-0">
                            <div className="col-12 formBtns">
                                <div className="pullLeft">
                                    <Button className="btn_send" id="send_msg" 
                                        onClick={() => this.chatBotpreviewStore.sendChatData(scriptStore)}>
                                        <p>{appstore.getLanguageTrans("Send")}</p>
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
});

export default ChatPreviewComponent;