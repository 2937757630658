import { decorate, observable, action } from 'mobx';
import axios from 'axios';

class ChatBotpreviewStore {

    editScriptRef;

    constructor(appStore) {
        this.appStore = appStore;
        this.messages = []; 
        this.embeddedfilepaths = [];        
    }

    // Fetch all embedded file paths which are enabled based on selectedWebisiteUid.
    async fetchEmbeddedFilepaths(appstore) {
        try {
            const result = await axios({
                method: 'GET',
                url: `${appstore.url}getEmbeddedFilepaths.json`,
                headers: { 'Content-Type': 'application/json' },
                params: { website_uid: appstore.selectedWebisiteUid },
            });
            if (result.data != null) {                
                this.embeddedfilepaths = result.data.webembdFilepath;                
            }
        } catch (error) {
            console.error('Error fetchEmbeddedFilepaths :', error);
        }
    }

    // Send chat data and update the messages array.
    async sendChatData(scriptStore) {
        const usermsgs = scriptStore.chatUserData.trim(); // Get user input.

        if (!usermsgs) {
            return;
        }

        try {
            // Append the user's question to the messages array.
            this.messages.push({ sender: "user", text: usermsgs });
            const webembdFilepath = this.embeddedfilepaths;    
            const response = await this.questionAndAnswer_to_AI(usermsgs, webembdFilepath);

            if (response) {
                this.messages.push({ sender: "ai", text: response });
            }
            scriptStore.chatUserData = "";
        } catch (error) {
            console.error("Error in sendChatData:", error);
           // alert("An error occurred while sending your message.");
        }
    }

    // Call the AI API for question and answer.
    async  questionAndAnswer_to_AI(userQuestion, webembdFilepath) {
        const endpoint = `${this.appStore.chatbotnodeurl}chat`;    
    
        try {
            const response = await fetch(endpoint, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    question: userQuestion,
                    filepath: webembdFilepath,
                }),
            });
    
            // Handle non-OK HTTP responses
            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status} - ${response.statusText}`);
            }
    
            // Attempt to parse the response as JSON
            let data;
            try {
                data = await response.json();
            } catch (jsonError) {
                throw new Error('Failed to parse response as JSON.');
            }
    
            return data.message || "No response message available.";
        } catch (error) {
            console.error('Fetch operation failed:', error);
            return null; // Return a fallback value for error scenarios
        }
    }    
}

decorate(ChatBotpreviewStore, {
    messages: observable, 
    embeddedfilepaths: observable, 
    fetchEmbeddedFilepaths: action, 
    sendChatData: action, 
    previewOnFocus : observable,
    chatUserData : observable
});

export default ChatBotpreviewStore;
